<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Trainee Planning Calendar</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="md-6">
                                <b-form-group label-for="search-lastname"
                                              label="Trainee's Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-lastname"
                                                 placeholder="Last Name"
                                                 v-model="criteria.lastname"/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.lastname = null">
                                                <font-awesome-icon icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-6">
                                <b-form-group label-for="search-ubc-id"
                                              label="Trainee's UBC ID:">
                                    <b-input-group size="sm">
                                        <b-input id="search-ubc-id"
                                                 v-model="criteria.ubcId"
                                                 placeholder="UBC ID"  />
                                        <b-input-group-addon>
                                            <b-button @click="criteria.ubcId = null">
                                                <font-awesome-icon icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="md-6">
                                <b-form-group label-for="search-program"
                                              label="Program:">
                                    <b-input-group size="sm">
                                        <b-select id="search-program"
                                                  v-model="criteria.programs"
                                                  :options="programOptions"
                                                  :select-size="4"
                                                  multiple/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.programs = []; criteria.session = null">
                                                <font-awesome-icon icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-6"
                                   v-if="!!criteria.programs && criteria.programs.length > 0">
                                <b-form-group label-for="search-session" label="Session:">
                                    <picker-of-dates v-model="criteria.session"
                                                     size="sm"
                                                     placeholder="Select Month/Year"
                                                     type="month"
                                                     format="MMMM yyyy"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="text-right">
                        <b-button-group size="sm">
                            <b-button variant="primary" @click="doSearch">Search All Trainees</b-button>
                            <b-button variant="success" @click="doSearchEnabled">Search Enrolled Trainees Only</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
                <b-card no-body>
                    <b-card-body v-if="!!searchResults && searchResults.length > 0">
                        <b-row>
                            <b-col cols="4">
                                <b-pagination :total-rows="searchResults.length"
                                              :per-page="perPage"
                                              v-model="currentPage"/>
                            </b-col>
                            <b-col cols="8">
                                <b-form-group :label="'Records Per Page: ' + perPage">
                                    <b-input type="range"
                                             v-model="perPage"
                                             min="10"
                                             max="100"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>Page {{ currentPage }} of {{ pages }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table small striped hover bordered no-local-sorting
                                         stacked="md"
                                         head-variant="dark"
                                         ref="searchResultsTable"
                                         selectable
                                         select-mode="single"
                                         selected-variant="warning"
                                         @row-selected="onRowSelected"
                                         :items="searchResults"
                                         :fields="fields"
                                         :per-page="perPage"
                                         :current-page="currentPage">
                                    <template #cell(status)="row">
                                        {{row.item.traineeStatus.status}}
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card>
                    <b-card-body>
                        <b-row>
                            <b-col cols="8"/>
                            <b-col cols="4">
                                <b-form-group
                                        label-for="display-purposes"
                                        label="Selected User:">
                                    <b-form-input
                                            id="display-purposes"
                                            size="lg"
                                            readonly
                                            v-model="selectedUser"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer>
                        <b-row>
                            <b-col class="text-right">
                                <b-button-group size="sm">
                                    <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                                    <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                                    <b-button variant="info" @click="clearForm">Clear</b-button>
                                </b-button-group>
                            </b-col>
                        </b-row>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import _ from "underscore";
import {SecurityLevel} from "../../../model/security_level";
import {sprintf} from 'sprintf-js';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";
import PickerOfDates from '@/components/shared/PickerOfDates';

@Component({
    components: {
        PickerOfDates
    }
})
export default class TraineePlanningCalendar extends Vue {
    reportTargetUser = {};
    currPage = 1;
    perPage = 10;

    onRowSelected(selected) {
        const user = selected.pop();
        this.reportTargetUser = user ? user : {};
    }

    get criteria() {
        return this.$store.getters['users/searchCriteria'];
    }

    async doSearchEnabled() {
        this.criteria.traineeStatus = this.enrolledStatus.id;
        await this.doSearch();
    }

    async doSearch() {
        //TODO - validate at least one criterion is present?
        const searchCriteria = this.criteria;
        searchCriteria.role = this.traineeRole;
        try {
            const {limited, size} = await this.$store.dispatch('users/search', searchCriteria.serialize());
            this.currentPage = 1;
            if (limited) {
                this.$bvToast.toast(sprintf('The search results have been limited to %d users. Please choose more specific search criteria.', size), {
                    variant: 'danger',
                    title: 'Results Truncated',
                    solid: true
                });
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        } finally {
            this.criteria.traineeStatus = null;  //Clear as hidden filter
        }
    }

    clearForm() {
        this.criteria.clear();
        this.reportTargetUser = {};
        this.$store.commit('users/setUsers', []);
    }

    async getPdf() {
        if (!this.readyToGenerate()) {
            return;
        }
        try {
            await download('POST', '/api/v1/report/trainee-planning-calendar', JSON.stringify({
                userProfileId: this.reportTargetUser.id
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async getCsv() {
        if (!this.readyToGenerate()) {
            return;
        }
        try {
            await download('POST', '/api/v1/export/trainee-planning-calendar', JSON.stringify({
                userProfileId: this.reportTargetUser.id
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Trainee Planning Calendar', null, true));
        try {
            await this.$store.dispatch('trainees/loadTraineeStatuses');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    readyToGenerate() {
        if (_.isUndefined(this.reportTargetUser.id)) {
            this.$bvModal.msgBoxOk('A Trainee must be selected.', {
                size: 'sm',
                buttonSize: 'sm'
            });
            return false;
        }
        return true;
    }

    get programOptions() {
        return [
            'Trainee Program 1',
            'Trainee Program 2',
            'Trainee Program 3',
            'Trainee Program 4'
        ];
    }

    get searchResults() {
        const users = this.$store.getters['users/users'];
        return _.sortBy(users, u => u.lastName);
    }

    get traineeRole() {
        return SecurityLevel.TRAINEE;
    }

    get currentPage() {
        if (this.currPage > this.pages) {
            return this.pages;
        } else {
            return this.currPage;
        }
    }

    set currentPage(num) {
        const page = parseInt(num, 10);
        switch (true) {
            case _.isNaN(page):
            case 0 >= page:
                this.currPage = 1;
                break;
            case page > this.pages:
                this.currPage = this.pages;
                break;
            default:
                this.currPage = page;
        }
    }

    get selectedUser() {
        const user = this.reportTargetUser;
        if (user.firstName === undefined) {
            return undefined;
        }
        else {
            return user.firstName + " " + user.lastName;
        }
    }

    get pages() {
        return Math.ceil(this.searchResults.length / this.perPage) || 1;
    }

    get fields() {
        return [{
            key: 'ubcId',
            label: 'UBC ID',
            sortable: true
        }, {
            key: 'firstName',
            label: 'First Name',
            sortable: true
        }, {
            key: 'lastName',
            label: 'Last Name',
            sortable: true
        }, {
            key: 'securityLevel',
            label: 'Role',
            sortable: true
        }, {
            key: 'status',
            label: 'Status',
            sortable: true
        }, {
            key: 'organization.name',
            label: 'Organization',
            sortable: true
        }];
    }

    get enrolledStatus() {
        const traineeStatuses = this.$store.getters['trainees/statuses'];
        const enrolled = _.find(traineeStatuses, status => {
            return status.status.toUpperCase() === 'ENROLLED';
        });
        return enrolled;
    }
}
</script>

<style scoped>

</style>